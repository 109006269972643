@import "../../scss/globalColor.scss";

.splash-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }

  .splash-title-container {
    font-family: "Agustina Regular", cursive;
    font-weight: bold;
    font-variant-ligatures: no-common-ligatures;
    -webkit-font-variant-ligatures: no-common-ligatures;
    font-size: 2.5rem;
    text-decoration: none;
  }

  .brackets{
    font-size: 3.5rem;
  }
  .splash-title {
    padding: 0 20px;
  }