@import "../../scss/globalColor.scss";

.profile-icons-div{
    font-size: 2em;
}

.icon-button {
    margin-bottom: 10px;
}

.icon-button i {
    color: $textColor;
    border-radius: 2.6rem;
    cursor: pointer;
    display: inline-block;
    font-size: 1.3rem;
    height: 2.6rem;
    line-height: 2.6rem;
    margin: 0 5px;
    position: relative;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: 2.6rem;
    margin-bottom: 10px;
    transition: 0.2s ease-in;
}

.github i {
    background-color: $github;
}

.leetcode i {
    background-color: $leetcode;
}

.mail i {   
    background-color: $mail;
}

.linkedin i {
    background-color: $linkedin;
}

.github i:hover,
.leetcode i:hover,
.linkedin i:hover,
.mail i:hover{
    background-color: $iconHoverColor;
}

/* Media Query */
@media (max-width: 768px) {
    .profile-icons-div {
      text-align: center;
    }
  }