@import "./gitLanguageColors";

$darkBackground: #171c28;
$textColor: #fff;
$hamburgerColor: #fff;


//profile-icons colors
$linkedin: #0e76a8;
$github: #333;
$leetcode: #454545;
$mail: #ea4335;
$iconHoverColor: #000000;

//button colors
$buttonColor: #55198b;
$buttonHover: #8c43ce;
$topButtonHover: #000;
$boxShadow: #fff;

//dev-icons
$subTitle: #868e96;
$skillsColor: rgb(221, 91, 91);

//proficiency-area-bars
$progressBarBackground: rgb(243, 239, 239);
$progressBarSpanBG: #aaa5ff;

//GitRepoCards color scheme
$githubRepoCardLanguageColorBG: yellow;
$githubRepoCardColor: rgb(88, 96, 105);
$githubRepoCardRepoCardStatsColor: rgb(106, 115, 125);
$githubRepoCardRepoNameColor: rgb(36, 41, 46);
$githubProfileCardLocationTS: #ffebcd;
$githubProfileCardBorder: #6c63ff;

// light background colors
$lightBackground1: #fff;
$lightBackground2: rgb(255, 255, 255);
$lightBackground3: #f5f2f4;
$blogCardContainerColor: #586069;
// dark background colors
$darkBackground: #171c28;

// light theme box shadows
$lightBoxShadowDark: rgba(0, 0, 0, 0.2);
$lightBoxShadow: rgba(0, 0, 0, 0.1);
$lightBoxShadowDarker: rgba(0, 0, 0, 0.3);

// dark theme box shadows
$darkBoxShadow: #d9dbdf;
$darkBoxShadow2: #ffffff;

//AchievementCard Component Colors
$titleColor: #fff;
$cardSubtitle: #ffebcd;
$darkBorderBox: #fff;
$achievementCertificateCardHover: white;
$buttonHover: #8c43ce;
