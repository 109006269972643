@import "../../scss/globalColor.scss";


.repo-card-div {
    background-color: $darkBackground;
    color: $textColor;
    box-shadow: $lightBoxShadowDark 30px 30px 30px -15px;
    padding: 2rem;
    cursor: pointer;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.repo-card-div p {
    color: $textColor;
}
  
.repo-card-div:hover {
    background-color: $buttonColor !important;
    box-shadow: 0px 0px 16px $darkBoxShadow;
}
  
.repo-stats {
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    font-size: 13px;
    color: $githubRepoCardRepoCardStatsColor;
}
  
.repo-left-stat {
    -webkit-box-flex: 1;
    flex-grow: 1;
    display: flex;
}
  
.language-color {
    width: 10px;
    height: 10px;
    background-color: $githubRepoCardLanguageColorBG;
    margin-right: 0.25rem;
    border-radius: 100%;
}
  
.repo-left-stat span {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    margin-right: 0.75rem;
}
  
.repo-name-div {
    display: flex;
    align-items: center;
}
  
.repo-svg {
    margin-right: 0.5rem;
    min-width: 16px;
    fill: $textColor;
}
  
.repo-name {
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $githubRepoCardRepoNameColor;
    margin-bottom: 0.75rem;
    font-size: 25px;
    font-weight: 700;
    letter-spacing: -0.5px;
    overflow: hidden;
    margin: 0px;
    height: 30px;
}
  
.repo-star-svg {
    margin-right: 0.3rem;
}
  
.repo-description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
  