.achievement-cards{
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
    gap: 1rem 1rem; 
}

.achievements-main-container{
    padding: 30px;
    margin: 30px;
    margin-top:0;
    padding-top: 0;
}

.achievement-heading {
    font-size: 56px;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    margin-top: 1em;
}

.achievement-subtitle {
    text-transform: uppercase;
    margin: 0px;
    margin-bottom: 50px;
}

@media only screen and (max-width: 961px){
    .achievement-cards{
        display: block;
        padding: 4vw;
    }

    .achievement-heading {
        font-size: 30px;
        padding-left: 3vw;
        font-weight: bold;
    }

    .achievement-subtitle{
        font-size: 15px;
        padding-left: 3vw;
        margin-bottom: 0;
        padding-bottom: 0;
    }

    .achievements-main-container{
        margin: 0;
        padding: 0;
    }
}