@import "../../scss/globalColor.scss";

.main-button {
    background-color: $buttonColor;
    border: solid 1px $buttonColor;
    color: $textColor;
    font-weight: 700;
    width: max-content;
    padding: 13px 22px;
    margin-right: 50px;
    text-transform: uppercase;
    border-radius: 6px;
    text-align: center;
    text-decoration: none;
    display: block;
    margin-top: 20px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out 0s;
}

.main-button:hover {
    background-color: $boxShadow;
    color: $buttonColor;
    transition: all 0.3s ease 0s;
    transform: translateY(-3px);
}

.project-button {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.project-button > .main-button {
    margin-right: 0 !important;
}

  /* Media Query */
@media (max-width: 768px) {
    .main-button {
      font-size: 0.938rem;
      padding: 12px 18px;
      margin-right: 0px;
    }
}

@media (max-width: 320px) {
    .main-button {
      font-size: 0.75rem;
    }
}