@import "../../scss/globalColor.scss";

.introduction-container{
    display: flex;
    padding: 0px 2vw 2vh 2vw;
}

.about{
    width: 60%;
    margin: auto;
    padding: 10px;
}

.lottie-anim{
    width: 40%;
    margin: auto;
    padding: 10px;
}

.greeting-text {
    font-size: calc(30px + 2vw);
    line-height: 1.1;
    color: $textColor !important;
}

.greeting-text-p {
    font-size: calc(10px + 1vw);
    line-height: 40px;
}

.wave-emoji {
    animation-duration: 1.8s;
    animation-iteration-count: infinite;
    animation-name: wave;
    display: inline-block;
    transform-origin: 70% 70%;
}

@keyframes wave {
    0% {
      transform: rotate(0deg);
    }
    10% {
      transform: rotate(-10deg);
    }
    20% {
      transform: rotate(12deg);
    }
    30% {
      transform: rotate(-10deg);
    }
    40% {
      transform: rotate(9deg);
    }
    50% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(0deg);
    }
  }

.contact-button-div{
  display: flex;
  margin-top: 5px;
}

@media only screen and (max-width: 961px){

  .introduction-container{
    display: block;
  }

  .lottie-anim{
    display: block;
    width: 80%;
    height: 80%;
  }

  .contact-button-div{
    display: flex;
    justify-content: center;
  }

  .greeting-text{
    font-size: 30px;
    text-align: center;
    margin: 0;
    padding-top: 1em;
  }

  .about{
    width: 100%;
    padding: 0;
  }

  .greeting-text-p{
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: $textColor;
    padding: 0.5em;
  }
  
  .main-button{
    margin: 2vw;
  }

}
