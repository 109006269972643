.skills-container{
    display: flex;
}

.skills-lottie-anim{
    width: 40%;
    margin: auto;
    padding: 10px;
}

.skills-text-container{
    width: 50%;
    margin: auto !important;
    padding: 10px;
    margin-bottom: 40px;
}

.skills-heading{
    font-size: calc(20px + 2vw);
}

.skills-subheading{
    font-size: calc(5px + 1vw);
}

.skills-list{
    list-style-type: none;
    line-height: 25px;
}

.skills-list > *{
    flex: 1;
    margin-bottom: 15px;
}

@media only screen and (max-width: 961px){
    .skills-container{
        display: block;
    }

    .skills-lottie-anim{
        display: none;
    }

    .skills-text-container{
        width: 100%;
        margin: 0;
        padding: 0;
    }

    .skills-heading{
        padding-left: 3vw;
        font-size: 35px;
    }

    .skills-subheading{
        padding-left: 3vw;
        font-size: 15px;
    }
    
    .software-skills-skill{
        padding-left: 5vw;
    }

    .skills-list{
        padding-right: 1em;
    }
}