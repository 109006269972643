.ABAP { background-color: #E8274B; }
.ActionScript { background-color: #882B0F; }
.Ada { background-color: #02f88c; }
.Agda { background-color: #315665; }
.AGS-Script { background-color: #B9D9FF; }
.Alloy { background-color: #64C800; }
.AMPL { background-color: #E6EFBB; }
.ANTLR { background-color: #9DC3FF; }
.API-Blueprint { background-color: #2ACCA8; }
.APL { background-color: #5A8164; }
.Arc { background-color: #aa2afe; }
.Arduino { background-color: #bd79d1; }
.ASP { background-color: #6a40fd; }
.AspectJ { background-color: #a957b0; }
.Assembly { background-color: #6E4C13; }
.ATS { background-color: #1ac620; }
.AutoHotkey { background-color: #6594b9; }
.AutoIt { background-color: #1C3552; }
.BlitzMax { background-color: #cd6400; }
.Boo { background-color: #d4bec1; }
.Brainfuck { background-color: #2F2530; }
.C-Sharp { background-color: #178600; }
.C { background-color: #555555; }
.Chapel { background-color: #8dc63f; }
.Cirru { background-color: #ccccff; }
.Clarion { background-color: #db901e; }
.Clean { background-color: #3F85AF; }
.Click { background-color: #E4E6F3; }
.Clojure { background-color: #db5855; }
.CoffeeScript { background-color: #244776; }
.ColdFusion-CFC { background-color: #ed2cd6; }
.ColdFusion { background-color: #ed2cd6; }
.Common-Lisp { background-color: #3fb68b; }
.Component-Pascal { background-color: #b0ce4e; }
.cpp { background-color: #f34b7d; }
.Crystal { background-color: #776791; }
.CSS { background-color: #563d7c; }
.D { background-color: #ba595e; }
.Dart { background-color: #00B4AB; }
.Diff { background-color: #88dddd; }
.DM { background-color: #447265; }
.Dogescript { background-color: #cca760; }
.Dylan { background-color: #6c616e; }
.E { background-color: #ccce35; }
.Eagle { background-color: #814C05; }
.eC { background-color: #913960; }
.ECL { background-color: #8a1267; }
.edn { background-color: #db5855; }
.Eiffel { background-color: #946d57; }
.EJS {background-color: #a91e50;}
.Elixir { background-color: #6e4a7e; }
.Elm { background-color: #60B5CC; }
.Emacs-Lisp { background-color: #c065db; }
.EmberScript { background-color: #FFF4F3; }
.Erlang { background-color: #B83998; }
.F-Sharp { background-color: #b845fc; }
.Factor { background-color: #636746; }
.Fancy { background-color: #7b9db4; }
.Fantom { background-color: #dbded5; }
.FLUX { background-color: #88ccff; }
.Forth { background-color: #341708; }
.FORTRAN { background-color: #4d41b1; }
.FreeMarker { background-color: #0050b2; }
.Frege { background-color: #00cafe; }
.Game-Maker-Language { background-color: #8fb200; }
.Glyph { background-color: #e4cc98; }
.Gnuplot { background-color: #f0a9f0; }
.Go { background-color: #375eab; }
.Golo { background-color: #88562A; }
.Gosu { background-color: #82937f; }
.Grammatical-Framework { background-color: #79aa7a; }
.Groovy { background-color: #e69f56; }
.Handlebars { background-color: #01a9d6; }
.Harbour { background-color: #0e60e3; }
.Haskell { background-color: #29b544; }
.Haxe { background-color: #df7900; }
.HTML { background-color: #e44b23; }
.Hy { background-color: #7790B2; }
.IDL { background-color: #a3522f; }
.Io { background-color: #a9188d; }
.Ioke { background-color: #078193; }
.Isabelle { background-color: #FEFE00; }
.J { background-color: #9EEDFF; }
.Java { background-color: #b07219; }
.JavaScript { background-color: #f1e05a; }
.JFlex { background-color: #DBCA00; }
.JSONiq { background-color: #40d47e; }
.Julia { background-color: #a270ba; }
.Jupyter-Notebook { background-color: #DA5B0B; }
.Kotlin { background-color: #F18E33; }
.KRL { background-color: #28431f; }
.Lasso { background-color: #999999; }
.Latte { background-color: #A8FF97; }
.Lex { background-color: #DBCA00; }
.LFE { background-color: #004200; }
.LiveScript { background-color: #499886; }
.LOLCODE { background-color: #cc9900; }
.LookML { background-color: #652B81; }
.LSL { background-color: #3d9970; }
.Lua { background-color: #000080; }
.Makefile { background-color: #427819; }
.Mask { background-color: #f97732; }
.Matlab { background-color: #bb92ac; }
.Max { background-color: #c4a79c; }
.MAXScript { background-color: #00a6a6; }
.Mercury { background-color: #ff2b2b; }
.Metal { background-color: #8f14e9; }
.Mirah { background-color: #c7a938; }
.MTML { background-color: #b7e1f4; }
.NCL { background-color: #28431f; }
.Nemerle { background-color: #3d3c6e; }
.nesC { background-color: #94B0C7; }
.NetLinx { background-color: #0aa0ff; }
.NetLinx-ERB { background-color: #747faa; }
.NetLogo { background-color: #ff6375; }
.NewLisp { background-color: #87AED7; }
.Nimrod { background-color: #37775b; }
.Nit { background-color: #009917; }
.Nix { background-color: #7e7eff; }
.Nu { background-color: #c9df40; }
.Objective-C { background-color: #438eff; }
.Objective-Cpp { background-color: #6866fb; }
.Objective-J { background-color: #ff0c5a; }
.OCaml { background-color: #3be133; }
.Omgrofl { background-color: #cabbff; }
.ooc { background-color: #b0b77e; }
.Opal { background-color: #f7ede0; }
.Oxygene { background-color: #cdd0e3; }
.Oz { background-color: #fab738; }
.Pan { background-color: #cc0000; }
.Papyrus { background-color: #6600cc; }
.Parrot { background-color: #f3ca0a; }
.Pascal { background-color: #b0ce4e; }
.PAWN { background-color: #dbb284; }
.Perl { background-color: #0298c3; }
.Perl6 { background-color: #0000fb; }
.PHP { background-color: #4F5D95; }
.PigLatin { background-color: #fcd7de; }
.Pike { background-color: #005390; }
.PLSQL { background-color: #dad8d8; }
.PogoScript { background-color: #d80074; }
.Processing { background-color: #0096D8; }
.Prolog { background-color: #74283c; }
.Propeller-Spin { background-color: #7fa2a7; }
.Puppet { background-color: #302B6D; }
.Pure Data { background-color: #91de79; }
.PureBasic { background-color: #5a6986; }
.PureScript { background-color: #1D222D; }
.Python { background-color: #3572A5; }
.QML { background-color: #44a51c; }
.R { background-color: #198ce7; }
.Racket { background-color: #22228f; }
.Ragel-in-Ruby-Host { background-color: #9d5200; }
.RAML { background-color: #77d9fb; }
.Rebol { background-color: #358a5b; }
.Red { background-color: #ee0000; }
.Ren-Py { background-color: #ff7f7f; }
.Rouge { background-color: #cc0088; }
.Ruby { background-color: #701516; }
.Rust { background-color: #dea584; }
.SaltStack { background-color: #646464; }
.SAS { background-color: #B34936; }
.Scala { background-color: #DC322F; }
.Scheme { background-color: #1e4aec; }
.Self { background-color: #0579aa; }
.Shell { background-color: #89e051; }
.Shen { background-color: #120F14; }
.Slash { background-color: #007eff; }
.Slim { background-color: #ff8f77; }
.Smalltalk { background-color: #596706; }
.SourcePawn { background-color: #5c7611; }
.SQF { background-color: #3F3F3F; }
.Squirrel { background-color: #800000; }
.Stan { background-color: #b2011d; }
.Standard-ML { background-color: #dc566d; }
.SuperCollider { background-color: #46390b; }
.Swift { background-color: #ffac45; }
.SystemVerilog { background-color: #DAE1C2; }
.Tcl { background-color: #e4cc98; }
.TeX { background-color: #3D6117; }
.Turing { background-color: #45f715; }
.TypeScript { background-color: #2b7489; }
.Unified-Parallel-C { background-color: #4e3617; }
.Unity3D-Asset { background-color: #ab69a1; }
.UnrealScript { background-color: #a54c4d; }
.Vala { background-color: #fbe5cd; }
.Verilog { background-color: #b2b7f8; }
.VHDL { background-color: #adb2cb; }
.VimL { background-color: #199f4b; }
.Visual-Basic { background-color: #945db7; }
.Volt { background-color: #1F1F1F; }
.Vue { background-color: #2c3e50; }
.Web-Ontology-Language { background-color: #9cc9dd; }
.wisp { background-color: #7582D1; }
.X10 { background-color: #4B6BEF; }
.xBase { background-color: #403a40; }
.XC { background-color: #99DA07; }
.XQuery { background-color: #5232e7; }
.Zephir { background-color: #118f9e; }